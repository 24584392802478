// import React from "react"
// import Meta from "../utilities/seo"
// import Footer from "../components/layout/footer"
// import Main from "../components/layout/main"
// import HelloJan from "../microsites/hello-january/wrapper"
// const Page = () => {
//   return (
//     <>
//       <Meta
//         title="Hello January"
//         description="The Hello January Challenge is a 90-day savings challenge aimed at galvanizing a community of people around healthy spending and saving habits"
//         image={{
//           url: "https://res.cloudinary.com/xeno-technologies/image/upload/v1665065975/cover_owk7r5.jpg",
//           height: "1080",
//           width: "1080",
//         }}
//       />
//       <Main>
//         <HelloJan />
//         <Footer />
//       </Main>
//     </>
//   )
// }

// export default Page

import React, { useEffect } from "react"
import Meta from "../utilities/seo"

const Ele = () => {
  useEffect(() => {
    window.location.href =
      "https://join.myxeno.com/?country=KE&goal=Hello%20January%20Challenge"
  }, [])
  return (
    <>
      <Meta title="Hello January" />
    </>
  )
}

export default Ele
